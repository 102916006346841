import {Popup} from "../index";
import useLadyService from "../../../services/LadyService";
import {Button, InputInLabel, Toggle} from "../../ui";
import {useSelector} from "react-redux";
import {getEditOperatorStatus, setEditOperatorStatus} from "../../../stores/slices/popupSlice";
import showToast from "../../toast/Toast";
import {makeRequest} from "../../../services/makeRequest";
import {useForm} from "react-hook-form";
import {useState} from "react";

const OperatorPopup = ({edit, operator, getOperatorsList}) => {
  const {dispatch, t, lang, resultBalance, userType, windowWidth} = useLadyService();

  const open = useSelector(getEditOperatorStatus)
  const handleClose = () => {
    reset()
    dispatch(setEditOperatorStatus(false))
  }

  const [dataList, setDataList] = useState([
    {title: "agencyprofileman", checked: false},
    {title: "adsamanage", checked: false},
    {title: "promoandads", checked: false},
    {title: "reviewmanage", checked: false},
    {title: "partiesmanage", checked: false},
    {title: "statsaccess", checked: false},
    {title: "balancemanage", checked: false},
  ]);

  const [dataListSecond, setDataListSecond] = useState([
    {
      title: "videochats",
      checked: false,
      subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`,
      id: 7,
    },
    {
      title: "bookings",
      checked: false,
      subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`,
      id: 8,
    },
    {
      title: "gotopreviews",
      checked: false,
      subTitle: `${t("confirmation")} ${t("and")} ${t("rejection")}`,
      id: 9,
    },
  ]);

  const handleToggleChange = (index, isSecondList = false) => {
    if (isSecondList) {
      setDataListSecond((prev) =>
        prev.map((item, idx) => (idx === index ? {...item, checked: !item.checked} : item))
      );
    } else {
      setDataList((prev) =>
        prev.map((item, idx) => (idx === index ? {...item, checked: !item.checked} : item))
      );
    }
  };

  const {
    register,
    handleSubmit,
    reset
  } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data, e) => {
    e.preventDefault()

    const fetchData = async () => {
      try {
        const route = edit ? `agency/operator` : `agency/operator`;
        const method = edit ? "PUT" : "POST";
        const payload = {
          name: edit ? operator.name : data.name,
          email: edit ? operator.email : data.email,
          settings: edit ? operator.settings : data.settings
        };

        const result = await makeRequest({route, method, payload});
        if (result) {
          getOperatorsList()
          showToast({
            message: t("success"),
            variant: "success",
          });

          setDataList((prev) =>
            prev.map((item) => ({ ...item, checked: false }))
          );
          setDataListSecond((prev) =>
            prev.map((item) => ({ ...item, checked: false }))
          );

          handleClose()
        }
      } catch (error) {
        const res = error.response;
        switch (res.status) {
          case 400: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 503: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };
    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleClose}
      id={"editOperator"}
      onSubmit={handleSubmit(onSubmit)}
      clazz={'popup-form__body_large popup-form--operator gap-24'}
    >
      <h2 className="m-0 mt-16 p-0-28">
        {t("editaddoperator")}
      </h2>

      <div className="popup-form__inner popup-form--operator__grid-inputs">
        <InputInLabel
          id={"operatorName"}
          autocomplete={'name'}
          required
          disabled={edit}
          value={edit ? operator.name : null}
          placeholder={"Valentin"}
          register={{...register("name")}}
        >
          {t("names")}
        </InputInLabel>

        <InputInLabel
          id={"operatorEmail"}
          type={"email"}
          autocomplete={'email'}
          value={edit ? operator.email : null}
          required
          disabled={edit}
          placeholder={"valentin-mang@gmail.com"}
          register={{...register("email")}}
        >
          Email
        </InputInLabel>
      </div>

      <h3 className={'m-0 text-left'}>{t("setrightsoper")}</h3>

      <div className="popup-form--operator__grid-toggles">
        <div className="popup-form__inner gap-12">
          {dataList.map((i, index) => (
              <div key={index} className="d-flex gap-12 align-center justify-sb">
                <span className={'color-main'}>{t(i.title)}</span>

                <Toggle
                  id={`operatorSetting-${index}`}
                  checked={edit ? i.title === operator.settings[index] : i.checked}
                  value={i.title}
                  onChange={() => handleToggleChange(index)}
                  name={'settings'}
                  register={{...register("settings")}}
                />
              </div>
            ))}
        </div>

        <div className="popup-form__inner gap-12">
          {dataListSecond.map((i, index) => (
              <div key={index} className="d-flex gap-12 align-center justify-sb">
                <div className="d-flex gap-6 fd-column">
                  <span className={'color-main'}>{t(i.title)}</span>
                  <span className={'color-600 p3'}>{i.subTitle}</span>
                </div>

                <Toggle
                  id={`operatorSetting-${i.id}`}
                  checked={edit ? i.title === operator.settings[i.id] : i.checked}
                  name={'settings'}
                  value={i.title}
                  onChange={() => handleToggleChange(index, true)}
                  register={{...register("settings")}}
                />
              </div>
            ))}
        </div>
      </div>

      <div className="popup-form--operator__buttons">
        <Button
          size={"l-forever"}
          clazz={"button--secondary"}
          buttonType={"reset"}
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          clazz={"button--green w-100"}
          buttonType={"submit"}
        >
          {edit ? t("save") : t("createnew")}
        </Button>
      </div>
    </Popup>
  );
};

export default OperatorPopup;
