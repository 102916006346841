import {Button, Icon, Loader, Toggle} from "../../../../../ui";
import useLadyService from "@/services/LadyService";
import BlockWrapper from "../../../components/block/BlockWrapper";
import {OperatorPopup} from "../../../../../popups";
import {setEditOperatorStatus} from "../../../../../../stores/slices/popupSlice";
import {Fragment, useEffect, useState} from "react";
import {makeRequest} from "../../../../../../services/makeRequest";
import showToast from "../../../../../toast/Toast";

const OperatorsPage = () => {
  const {t, dispatch} = useLadyService()

  const [editOperator, setEditOperator] = useState(false);

  const [loading, setLoading] = useState(false);

  const [operatorData, setOperatorData] = useState({
    id: null,
    name: '',
    email: '',
    settings: [],
    status: 0
  });

  const openEditOperator = (edit, dataObj) => {
    if (edit) {
      setEditOperator(true);
      setOperatorData({
        id: dataObj.id,
        name: dataObj.name,
        email: dataObj.email,
        settings: dataObj.settings,
        status: dataObj.status
      })
    } else {
      setEditOperator(false);
    }

    dispatch(setEditOperatorStatus(true))
  }

  const [data, setData] = useState([]);

  const getOperatorsList = async () => {
    try {
      setLoading(true);
      const route = `agency/operator`;
      const method = "GET";
      const payload = {};

      const result = await makeRequest({route, method, payload});
      if (result) {
        setData(result.data)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const res = error.response;
      switch (res.status) {
        case 400: {
          showToast({
            message: t("noauth"),
            variant: "error",
          });
          break;
        }
        case 503: {
          showToast({
            message: t("oops"),
            variant: "error",
          });
          break;
        }
      }
    }
  };
  useEffect(() => {
    getOperatorsList();
  }, [])

  const deleteOperator = async (id) => {
    try {
      const route = `agency/operator/${id}`;
      const method = "DELETE";
      const payload = {};

      const result = await makeRequest({route, method, payload});
      if (result) {
        showToast({
          message: t("success"),
          variant: "success",
        });

        setData((prevData) => prevData.filter(operator => operator.id !== id));
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  }

  const changeStatus = async (id, index) => {
    try {
      const route = `agency/set-operator-status/${id}`;
      const method = "POST";
      const payload = {};

      const result = await makeRequest({route, method, payload});
      if (result) {
        setData((prevData) =>
          prevData.map((item, idx) =>
            idx === index ? { ...item, status: !item.status } : item
          )
        );
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
      setData((prevData) =>
        prevData.map((item, idx) =>
          idx === index ? { ...item, status: !item.status } : item
        )
      );
    }
  }

  return (
    <main className={'profiles-page__container w-100'}>
      <div className="profiles-page__header">
        <h1>{t("myoperator")}</h1>
        <Button
          size={'l-forever'}
          clazz={'button--green'}
          onClick={() => openEditOperator()}
        >
          <Icon
            size={"l"}
            spritePath={"plus-1"}
          />
          {t('addad')}
        </Button>
      </div>

      <div className="d-flex fd-column gap-12">
        {loading ?
          <Loader height={300} decimal={'px'} loading={loading}/>
          :
          data.map((i, index) => (
          <Fragment key={index}>
            <BlockWrapper clazz={'block-wrapper--operator'}>
              <div className="block-wrapper--operator__name">
                <Toggle
                  checked={i.status}
                  onChange={() => changeStatus(i.id ,index)}
                />

                <div className="d-flex fd-column gap-8">
                  <b className={'color-500 p3'}>{t('names')}</b>
                  <span className={'p1 color-main'}>{i.name}</span>
                </div>
              </div>

              <div className="block-wrapper--operator__wrapper">
                <div className="d-flex fd-column gap-8">
                  <b className={'color-500 p3'}>Email (login)</b>
                  <span className={'p1 color-main'}>{i.email}</span>
                </div>

                <div className={'d-flex gap-8 justify-end'}>
                  <Button
                    size={'m'}
                    clazz={'button--secondary'}
                    onClick={() => openEditOperator(true, i)}
                  >
                    {t('edit')}
                  </Button>

                  <Button
                    size={'m'}
                    clazz={'button--error'}
                    onClick={() => deleteOperator(i.id)}
                  >
                    {t('delete')}
                  </Button>
                </div>
              </div>
            </BlockWrapper>
          </Fragment>
        ))}
      </div>

      <OperatorPopup
        edit={editOperator}
        operator={operatorData}
        getOperatorsList={getOperatorsList}
      />
    </main>
  )
}

export default OperatorsPage